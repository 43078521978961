import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonSkeletonText, IonTitle, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import Chartjs from "../components/Chart";
import Tabs from "../components/CustomTab";
import { RouteComponentProps, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import TokenImage from "../components/TokenImage";
import NumberFormatter from "../components/NumberFormatter";

interface TokenParams {
  tokenaddress: string;
}

interface TokenFile {
  uri: string;
  cdn_uri: string;
  mime: string;
}

interface TokenMetadata {
  description: string;
  name: string;
  symbol: string;
  token_standard: string;
}

interface TokenLinks {
  image: string;
}

interface TokenContent {
  $schema: string;
  json_uri: string;
  files: TokenFile[];
  metadata: TokenMetadata;
  links: TokenLinks;
}

interface TokenAuthority {
  address: string;
  scopes: string[];
}

interface TokenCompression {
  eligible: boolean;
  compressed: boolean;
  data_hash: string;
  creator_hash: string;
  asset_hash: string;
  tree: string;
  seq: number;
  leaf_id: number;
}

interface TokenRoyalty {
  royalty_model: string;
  target: null | string;
  percent: number;
  basis_points: number;
  primary_sale_happened: boolean;
  locked: boolean;
}

interface TokenOwnership {
  frozen: boolean;
  delegated: boolean;
  delegate: null | string;
  ownership_model: string;
  owner: string;
}

interface TokenPriceInfo {
  price_per_token: number;
  currency: string;
}

interface TokenInfo {
  symbol: string;
  supply: number;
  decimals: number;
  token_program: string;
  price_info: TokenPriceInfo;
}

interface TokenResponse {
  interface: string;
  id: string;
  content: TokenContent;
  authorities: TokenAuthority[];
  compression: TokenCompression;
  grouping: any[];
  royalty: TokenRoyalty;
  creators: any[];
  ownership: TokenOwnership;
  supply: null | number;
  mutable: boolean;
  burnt: boolean;
  token_info: TokenInfo;
}

//token Info Interface
interface Website {
  label: string;
  url: string;
}

interface Social {
  type: 'twitter' | 'telegram';
  url: string;
}

interface TokenInfo {
  imageUrl: string;
  header: string;
  openGraph: string;
  websites: Website[];
  socials: Social[];
}

interface Token {
  address: string;
  name: string;
  symbol: string;
}

interface TimeFrameData {
  buys: number;
  sells: number;
}

interface VolumeData {
  h24: number;
  h6: number;
  h1: number;
  m5: number;
}

interface PriceChangeData {
  m5: number;
  h1: number;
  h6: number;
  h24: number;
}

interface Liquidity {
  usd: number;
  base: number;
  quote: number;
}

interface TokenPair {
  chainId: string;
  dexId: string;
  url: string;
  pairAddress: string;
  baseToken: Token;
  quoteToken: Token;
  priceNative: string;
  priceUsd: string;
  txns: {
    m5: TimeFrameData;
    h1: TimeFrameData;
    h6: TimeFrameData;
    h24: TimeFrameData;
  };
  volume: VolumeData;
  priceChange: PriceChangeData;
  liquidity: Liquidity;
  fdv: number;
  marketCap: number;
  pairCreatedAt: number;
  info: TokenInfo;
}

interface TokenData {
  schemaVersion: string;
  pairs: TokenPair[];
}

const TokenInfo: React.FC<RouteComponentProps<TokenParams>> = () => {
  const { tokenaddress } = useParams<TokenParams>();
  const [timeframe, setTimeframe] = useState<'hour' | 'day' | 'minute'>('day');

  const { data: tokenMetaData, isLoading } = useQuery({
    queryKey: ["token", tokenaddress],
    queryFn: () =>
      fetch(
        `https://narrative-server-production.up.railway.app/das/${tokenaddress}`
      ).then((res) => res.json()),
    staleTime: 5 * 60 * 1000,
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!tokenaddress,
  });


  const { data: tokenInfo, isLoading: TokenInfoIsLoading } = useQuery<TokenData>({
    queryKey: ["tokenInfo", tokenaddress, timeframe],
    queryFn: () =>
      fetch(
        `https://narrative-server-production.up.railway.app/gecko/search?q=${tokenaddress}`
      ).then((res) => res.json()),
    staleTime: 5 * 60 * 1000,
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!tokenaddress,
  });

  const { data: chartData, isLoading: ChartIsLoading } = useQuery({
    queryKey: ["chart", tokenaddress, timeframe],
    queryFn: () =>
      fetch(
        `https://narrative-server-production.up.railway.app/gecko/chart/${tokenaddress}/${timeframe}`
      ).then((res) => res.json()),
    staleTime: 5 * 60 * 1000,
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!tokenaddress,
  });

  const tabs = [
    {
      id: "tab1",
      label: "Info",
      content: <div className="flex flex-col gap-[0.2rem] px-4">
        <span>{tokenMetaData?.content?.metadata?.description}</span>
      </div>,
    },
    {
      id: "tab2",
      label: "Indexs",
      content: (
        <div className="px-4 flex justify-center">
          You currently dont hold any indexs
        </div>
      ),
    },
    {
      id: "tab3",
      label: "Transactions",
      content: (
        <div className="px-4 flex justify-center">
          You have zero transactions
        </div>
      ),
    },
  ];

  const BuySellRatio: React.FC<any> = ({ data }) => {
    const { buys, sells } = data;
    const total = buys + sells;
    
    // Calculate percentages
    const buyPercentage = (buys / total) * 100;
    const sellPercentage = (sells / total) * 100;
  
    return (
      <div className="rounded-xl flex flex-col items-center justify-center px-3 w-full bg-white">
      <div className="w-full h-full py-4">
        <div className="text-black flex justify-between">
          <span className="font-medium">BUYS</span>
          <span className="font-medium">SELLS</span>
        </div>
        <div className="flex flex-row w-full h-[10px] rounded-full overflow-hidden">
          <div 
            className="bg-[#06FFC3] h-full transition-all duration-300 ease-in-out" 
            style={{ width: `${buyPercentage}%` }}
          />
          <div 
            className="bg-[#FF0655] h-full transition-all duration-300 ease-in-out" 
            style={{ width: `${sellPercentage}%` }}
          />
        </div>
        <div className="text-black pt-2 flex justify-between">
          <span className="text-xl font-bold">{buys}</span>
          <span className="text-xl font-bold">{sells}</span>
        </div>
      </div>
      </div>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/search" />
          </IonButtons>
          <IonTitle>
            {isLoading ? (
              <IonSkeletonText 
                animated 
                style={{ width: '50%' }} 
              />
            ) : (
              tokenMetaData?.content?.metadata?.name || 'Token Info'
            )}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>
        <div className="py-6 px-4">
          {isLoading ? (
            <>
              <IonSkeletonText 
                animated 
                style={{ width: '60%', height: '20px' }} 
              />
              <IonSkeletonText 
                animated 
                style={{ width: '80%', height: '40px', marginTop: '12px' }} 
              />
              <IonSkeletonText 
                animated 
                style={{ width: '40%', height: '20px', marginTop: '8px' }} 
              />
            </>
          ) : (
            <div className="grid grid-cols-6">

            <div className="grid col-span-5">
              <div className="text-[#7c7c7c]">
                {tokenMetaData?.content?.metadata?.name}
              </div>
              <h3 className="mt-2 font-bold text-4xl">
                ${tokenInfo?.pairs[0]?.priceUsd}
              </h3>
              <div className="mt-1 flex gap-1">
                <span className="text-[#05C396]">+${(Number(tokenInfo?.pairs[0]?.priceUsd) * (tokenInfo?.pairs[0]?.priceChange.m5 as number / 100)).toFixed(8) || 0} </span>
                <span className="bg-[#05C39640] text-[#05C396] px-[0.4rem] rounded-sm">{tokenInfo?.pairs[0].priceChange.m5 || 0}%</span>
              </div>
            </div>

            <div className="grid justify-center">
              <TokenImage src={tokenMetaData?.content?.links?.image} size={80} />
            </div>
            </div>
          )}
        </div>
        {ChartIsLoading ? (
          <div className="px-4">
            <IonSkeletonText animated style={{ width: '100%', height: '200px' }} />
          </div>
        ) : (
         <Chartjs 
           data={chartData} 
           timeframe={timeframe} 
           onTimeframeChange={setTimeframe} 
           />
         )}
        <div className="p-4">
         <div className="rounded-xl flex flex-col items-center justify-center px-3 w-full bg-white">
           {TokenInfoIsLoading ? (
           <>
             <IonSkeletonText animated style={{ width: '100%', height: '20px', marginBottom: '8px' }} />
             <IonSkeletonText animated style={{ width: '100%', height: '10px' }} />
           </>
           ) : (
            <BuySellRatio data={tokenInfo?.pairs[0]?.txns.h24} />
           )}
           <div className="text-black">
            <span>MC: <NumberFormatter value={tokenInfo?.pairs[0].fdv as number} decimals={2} /></span>
            <span>24H Volume: <NumberFormatter value={tokenInfo?.pairs[0].volume.h24 as number} decimals={2} /></span>
           </div>
         </div>
        </div>

        {isLoading ? (
          <div className="px-4">
            <IonSkeletonText animated style={{ width: '100%', height: '150px' }} />
          </div>
        ) : (
         <Tabs tabs={tabs} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TokenInfo;