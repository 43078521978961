import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient();

const environmentId = import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID;

const cssOverrides = `
  .button.button--padding-large.button--primary.connect-button {
    border-radius: 0px !important;
  }
  .button.button--padding-large.button--primary.connect-button .rounded-0 {
    border-radius: 0px !important;
  }
  .dynamic-widget,
  .dynamic-widget *,
  [data-testid="dynamic-auth-modal"],
  [data-testid="dynamic-auth-modal"] * {
    border-radius: 0 !important;
  }
`;

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <DynamicContextProvider
      settings={{
        environmentId: environmentId,
        walletConnectors: [SolanaWalletConnectors],
        cssOverrides,
      }}
    >
      <App />
    </DynamicContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);