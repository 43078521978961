import { IonIcon, IonPage, useIonRouter } from "@ionic/react";
import { construct, logoApple, logoGoogle, mailOutline } from "ionicons/icons";
import { useAppContext } from "../context/AppContext";
import { DynamicWidget, DynamicConnectButton, useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { ReactNode, useEffect } from "react";

const Login: React.FC = () => {
  const { dispatch, state } = useAppContext();
  const { user } = useDynamicContext();
  const router = useIonRouter();
  
  useEffect(() => {
    // Only handle login if we have a user and aren't already authenticated
    if (user && !state.isAuthenticated) {
      dispatch({
        type: "LOGIN",
        payload: user
      });
    }
  }, [user]);

  // Separate effect for navigation
  useEffect(() => {
    if (state.isAuthenticated && state.user) {
      router.push("/home", undefined, 'replace');
    }
  }, [state.isAuthenticated, state.user]);

  const Bbtn = (): ReactNode => {
    return <button className="rounded-0">Journey of a thousand miles begins now</button>
  }

  if (state.isAuthenticated) {
    return null;
  }

  return (
    <IonPage>
      <section className="flex flex-col h-full w-full justify-center items-center px-12 py-24">
        <div className="flex flex-col h-full w-full items-center justify-between">
          <div className="flex flex-col items-center font-bold text-3xl">
            <h1 className="text-center ">Welcome</h1>
            <h1 className="text-center">to Narrative</h1>
          </div>
          <div className="flex flex-col w-full gap-4 text-center items-center">
            <div className="text-center ">
              <p>Bet on real time information</p>
            </div>

            <DynamicWidget innerButtonComponent={<Bbtn />} />

           
          </div>
        </div>
      </section>
    </IonPage>
  );
};

export default Login;
