import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  useIonRouter,
} from "@ionic/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TokenImage from "../components/TokenImage";
import ColorNumberFormatter from "../components/ColorNumberFormatter";
import NumberFormatter from "../components/NumberFormatter";
import { useHistory } from "react-router-dom";

interface DexScreenerResponse {
  schemaVersion: string;
  pairs: PairData[];
}

interface PairData {
  chainId: string;
  dexId: string;
  url: string;
  pairAddress: string;
  baseToken: Token;
  quoteToken: Token;
  priceNative: string;
  priceUsd: string;
  txns: Transactions;
  volume: VolumeData;
  priceChange: PriceChangeData;
  liquidity: LiquidityData;
  fdv: number;
  marketCap: number;
  pairCreatedAt: number;
  info: TokenInfo;
}

interface Token {
  address: string;
  name: string;
  symbol: string;
}

interface Transactions {
  m5: TransactionCount;
  h1: TransactionCount;
  h6: TransactionCount;
  h24: TransactionCount;
}

interface TransactionCount {
  buys: number;
  sells: number;
}

interface VolumeData {
  h24: number;
  h6: number;
  h1: number;
  m5: number;
}

interface PriceChangeData {
  m5: number;
  h1: number;
  h6: number;
  h24: number;
}

interface LiquidityData {
  usd: number;
  base: number;
  quote: number;
}

interface TokenInfo {
  imageUrl: string;
  header: string;
  openGraph: string;
  websites: Website[];
  socials: Social[];
}

interface Website {
  label: string;
  url: string;
}

interface Social {
  type: string;
  url: string;
}

const Search: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const router = useIonRouter();
  const queryClient = useQueryClient();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const { data, isLoading, error } = useQuery({
    queryKey: ["feed", debouncedSearch],
    queryFn: async () => {
      const response = await fetch(
        `https://narrative-server-production.up.railway.app/gecko/search?q=${debouncedSearch}`
      );
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    staleTime: 5 * 60 * 1000,
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: debouncedSearch.length > 2,
  });

  // Prefetch token data when hovering over search results
  const prefetchToken = async (tokenAddress: string) => {
    await queryClient.prefetchQuery({
      queryKey: ["token", tokenAddress],
      queryFn: () => 
        fetch(`https://narrative-server-production.up.railway.app/das/${tokenAddress}`)
          .then(res => res.json()),
      staleTime: 5 * 60 * 1000,
    });
  };

  const handleTokenClick = (tokenAddress: string) => {
    // Ensure data is in cache before navigation
    queryClient.prefetchQuery({
      queryKey: ["token", tokenAddress],
      queryFn: () => 
        fetch(`https://narrative-server-production.up.railway.app/das/${tokenAddress}`)
          .then(res => res.json()),
    }).then(() => {
      router.push(`/token/${tokenAddress}`);
    });
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle size="large">Search</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="bg-[#161616] h-[60px] flex items-center">
          <input
            className="bg-white outline-none w-full p-2 px-4 border-none text-white"
            style={{ backgroundColor: "inherit" }}
            placeholder="Search tokens, curations and narratives"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="py-2">
          {isLoading && <div className="text-white px-4">Loading...</div>}
          {error && (
            <div className="text-red-500 px-4">Error: {error.message}</div>
          )}

          <div className="flex flex-col gap-2">
            {data?.pairs?.slice(0, 3).map((market: any, index: number) => (
              <div
                key={index}
                className="bg-[#161616] px-4 py-2 flex flex-col gap-2"
                onClick={() => handleTokenClick(market.baseToken.address)}
                onMouseEnter={() => prefetchToken(market.baseToken.address)}
              >
                <div className="flex gap-2 items-center">
                  {market.info?.imageUrl && (
                    <TokenImage src={market.info.imageUrl} size={30} />
                  )}
                  <span className="text-md">
                    {market.baseToken?.name || "Unknown"}
                  </span>
                  <span className="text-[0.6rem] ml-auto px-2">
                    {market.dexId?.toLocaleUpperCase()}
                  </span>
                </div>
                <div>
                  <span className="py-6 text-2xl font-sans font-bold">
                    {market.priceUsd || "0.00"}
                  </span>
                  <ColorNumberFormatter
                    value={market.priceChange?.h1?.toString() || "0"}
                    className="px-3 font-mono"
                  />
                </div>
                <div className="flex items-center">
                  <span className="font-mono py-2">
                    <span className="text-[#868686]">TOKEN</span>{" "}
                    {market.baseToken?.address
                      ? `${market.baseToken.address.slice(0, 4)}...${market.baseToken.address.slice(-6)}`
                      : "Address unavailable"}
                  </span>
                  <span className="ml-auto font-mono">
                    MC: <NumberFormatter value={market.fdv} decimals={3} />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </IonContent>
    </>
  );
};

export default Search;