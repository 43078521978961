import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonToggle,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { WebAuthn } from "../components/WebAuthn";
import { useEffect, useState } from "react";
import { WebAuthnService } from "../utils/WebAuthnService";

import TurnkeyService from "../components/Turnkey";
import { useTheme } from "../context/ThemeContext";

const Profile: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { darkMode, toggleTheme } = useTheme();

  useEffect(() => {
    setIsAuthenticated(WebAuthnService.isAuthenticated());
  }, []);

  const handleAuthStateChange = (newState: boolean) => {
    setIsAuthenticated(newState);
  };

  const handleLogout = () => {
    WebAuthnService.logout();
    setIsAuthenticated(false);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>You</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* <WebAuthn onAuthStateChange={handleAuthStateChange} /> */}
        <IonItem>
          <IonLabel slot="start">
            {darkMode ? "Dark Mode" : "Light Mode"}
          </IonLabel>
          <IonToggle
            slot="end"
            checked={darkMode}
            onIonChange={toggleTheme}
            enableOnOffLabels={true} // Shows "ON" and "OFF" labels
            color="primary" // Changes the active color
            mode="ios" // Forces iOS style
            disabled={false} // Disables the toggle
            className="theme-toggle"
          />
        </IonItem>
        <TurnkeyService />
      </IonContent>
    </>
  );
};

export default Profile;
