import { IonPage, setupIonicReact } from "@ionic/react";
import {
  IonTabs,
  IonTab,
  IonTabBar,
  IonTabButton,
  IonIcon,
} from "@ionic/react";
import Home from "./Home";
import Search from "./Search";
import Profile from "./Profile";
import Cook from "./Cook";
import Wallet from "./Wallet";
import {
  playCircle,
  flaskSharp,
  personSharp,
  search,
  cashSharp,
  flame,
} from "ionicons/icons";

setupIonicReact();

const HomeMain: React.FC = () => {
  return (
    <IonPage>
      {/* Tabs */}
      <IonTabs>
        <IonTab tab="home">
          <div id="home-page">
            <Home />
          </div>
        </IonTab>
        <IonTab tab="search">
          <div id="search-page">
            <Search />
          </div>
        </IonTab>
        <IonTab tab="cook">
          <div id="cook-page">
            <Cook />
          </div>
        </IonTab>
        <IonTab tab="wallet">
          <div id="wallet-page">
            <Wallet />
          </div>
        </IonTab>
        <IonTab tab="profile">
          <div id="profile-page">
            <Profile />
          </div>
        </IonTab>

        <IonTabBar slot="bottom">
          <IonTabButton tab="home">
            <IonIcon icon={flame} />
            Hot
          </IonTabButton>
          <IonTabButton tab="search">
            <IonIcon icon={search} />
            Search
          </IonTabButton>
          <IonTabButton tab="cook">
            <IonIcon icon={flaskSharp} />
            Cook
          </IonTabButton>
          <IonTabButton tab="wallet">
            <IonIcon icon={cashSharp} />
            Wallet
          </IonTabButton>
          <IonTabButton tab="profile">
            <IonIcon icon={personSharp} />
            Profile
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonPage>
  );
};

export default HomeMain;
