import { ContextStateType } from "../context/AppContext";

export const getRedirectPath = (state: ContextStateType | undefined) => {
  if (state?.isAuthenticated) {
    return state.newUser ? "/recommendation" : "/home";
  }
  return "/login";
};

export const truncateString = (str: string, maxLength: number) => {
  // console.log("str", str);

  if (str.length <= maxLength) {
    return str;
  }

  return str.slice(0, maxLength - 3) + "...";
};
