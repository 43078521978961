import React from 'react';

const NumberFormatter = ({ value, decimals = 2 }: {value: number, decimals: number}) => {
  const formatNumber = (num: number) => {
    // Handle null, undefined, or non-numeric values
    if (!num || isNaN(num)) return '0';
    
    // Convert to absolute number for comparison
    const absNum = Math.abs(num);
    
    // Format billions
    if (absNum >= 1e9) {
      return `${(num / 1e9).toFixed(decimals)}B`;
    }
    
    // Format millions
    if (absNum >= 1e6) {
      return `${(num / 1e6).toFixed(decimals)}M`;
    }
    
    // Format thousands
    if (absNum >= 1e3) {
      return `${(num / 1e3).toFixed(decimals)}K`;
    }
    
    // Return original number if less than 1000
    return num.toFixed(decimals);
  };

  // Handle edge cases where value is null/undefined
  if (value === null || value === undefined) {
    return <span>--</span>;
  }

  // Add negative sign if number is negative
  const formattedValue = formatNumber(value);
  
  return (
    <span className="font-mono">
      {formattedValue}
    </span>
  );
};

export default NumberFormatter;