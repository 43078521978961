import React from 'react'
import { NativeBalance, TokenInfo } from '../pages/Wallet';
import { solanaLogo } from '../types/const';
import TokenImage from './TokenImage';

interface TokenCardProps {
    token: TokenInfo;  // For regular tokens
    isNative?: boolean;
    nativeBalance?: NativeBalance; // For SOL balance
}

const TokenCard = ({ token, isNative, nativeBalance }: TokenCardProps) => {
    if (isNative && nativeBalance) {
      return (
        <div className='bg-[#161616] text-white px-3 py-3 flex items-center'>
          <div>
            <TokenImage src={solanaLogo} />
          </div>
  
          <div className='px-3  h-full flex-col'>
            <span>Solana</span>
            <div>${nativeBalance.valueInUsd || 0}</div>
          </div>
  
          <div className='h-full flex-col ml-auto'>
            <div>{nativeBalance.balance.toFixed(4) || 0}</div>
          </div>
        </div>
      );
    }
  
    return (
      <div className='bg-[#161616] text-white px-3 py-3 flex items-center'>
        <div>
          <TokenImage src={token.image} />
        </div>
  
        <div className='px-3 h-full flex-col'>
          <span>{token.name}</span>
          <div>${token.value.toFixed(2)}</div>
        </div>
  
        <div className='h-full flex-col ml-auto'>
          <div>{token.balance.toFixed(2)}</div>
        </div>
      </div>
    );
  };

  export default TokenCard;