import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from '@ionic/react';

const Cook: React.FC = () => {
  const [ingredients, setIngredients] = useState({
    flour: '',
    sugar: '',
    eggs: '',
    milk: ''
  });

  const handleInputChange = (event: CustomEvent) => {
    const { name, value } = event.detail;
    setIngredients(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCook = () => {
    console.log('Cooking with ingredients:', ingredients);
    // Add your cooking logic here
  };

  return (
    <>
      <div id="cook-page">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Cook a token</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent  fullscreen>
        <div className='flex flex-col gap-[0.3rem]'>
          <div className='h-[100px] bg-[#161616] flex flex-row items-center px-4'>
          <IonImg
          style={{ width: '70px', height: 'auto' }}
          src="pump.jpg"
          alt="The Wisconsin State Capitol building in Madison, WI at night"
          ></IonImg>
            <h2 className='text-xl font-bold px-3'>Launch on pump</h2>
          </div>
          <div className='h-[100px] bg-[#161616] flex flex-row items-center px-4'>
          <IonImg
          style={{ width: '70px', height: 'auto' }}
          src="moonshot.jpg"
          alt="The Wisconsin State Capitol building in Madison, WI at night"
          ></IonImg>
            <h2 className='text-xl font-bold px-3'>Launch on Moonshot</h2>
          </div>
          <div className='mt-3 px-3'>
            <h3 className='text-lg'>Have other platforms? <span className='text-[#05C396] font-bold'>Recommend</span></h3>
          </div>
        </div>

      </IonContent>
      Launch on Pumpdotfun
      Launch on moonshot
      Launch on Tribe
      </div>
    </>
  );
};

export default Cook;

/*
       <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="6">
              <IonItem>
                <IonLabel position="floating">Flour (grams)</IonLabel>
                <IonInput
                  name="flour"
                  type="number"
                  value={ingredients.flour}
                  placeholder="Enter amount of flour"
                  onIonChange={handleInputChange}
                />
              </IonItem>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonItem>
                <IonLabel position="floating">Sugar (grams)</IonLabel>
                <IonInput
                  name="sugar"
                  type="number"
                  value={ingredients.sugar}
                  placeholder="Enter amount of sugar"
                  onIonChange={handleInputChange}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" sizeMd="6">
              <IonItem>
                <IonLabel position="floating">Eggs</IonLabel>
                <IonInput
                  name="eggs"
                  type="number"
                  value={ingredients.eggs}
                  placeholder="Enter number of eggs"
                  onIonChange={handleInputChange}
                />
              </IonItem>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonItem>
                <IonLabel position="floating">Milk (ml)</IonLabel>
                <IonInput
                  name="milk"
                  type="number"
                  value={ingredients.milk}
                  placeholder="Enter amount of milk"
                  onIonChange={handleInputChange}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={handleCook}>Cook</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
 */