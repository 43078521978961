import { IonIcon, IonPage } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import { useState, useEffect } from "react";
import { truncateString } from "../utils/random.";
import { server_url } from "../types/const";

const API_BASE_URL = server_url;

interface Specific {
  id: string;
  name: string;
  imageUrl: string;
  type: 'specific';
  parentIds: string[];
  tags: string[];
}

const Recommendation = () => {
  const [specifics, setSpecifics] = useState<Specific[]>([]);
  const [selectedRecommendations, setSelectedRecommendations] = useState<Record<string, true>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSpecifics = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/categories/all/flat`);
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        
        // Extract all specifics from the categories
        const allSpecifics = extractSpecifics(data.categories);
        setSpecifics(allSpecifics);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch specifics');
      } finally {
        setLoading(false);
      }
    };

    fetchSpecifics();
  }, []);

  // Helper function to extract specifics from categories
  const extractSpecifics = (categories: any[]): Specific[] => {
    const specificsSet = new Set<Specific>();
    
    const traverse = (node: any) => {
      if (node.type === 'specific') {
        specificsSet.add(node);
      }
      
      if (node.children) {
        node.children.forEach(traverse);
      }
    };
    
    categories.forEach(traverse);
    return Array.from(specificsSet);
  };

  const handleSelectRecommendation = (id: string) => {
    if (selectedRecommendations[id]) {
      const selected = { ...selectedRecommendations };
      delete selected[id];
      setSelectedRecommendations(selected);
      return;
    }
    setSelectedRecommendations(prev => ({
      ...prev,
      [id]: true,
    }));
  };

  if (loading) {
    return (
      <IonPage>
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      </IonPage>
    );
  }

  if (error) {
    return (
      <IonPage>
        <div className="flex items-center justify-center h-full text-red-500">
          {error}
        </div>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <section className="flex flex-col min-h-full overflow-auto w-full justify-between items-center px-4 gap-3 pb-8 text-mode-black bg-mode-white">
        <div className="flex flex-col w-full py-8 gap-3">
          <h1 className="text-2xl font-bold">Let's get you started</h1>
          <p className="text-sm text-[#BFBFBF]">What do you like?</p>

          <div className="pt-6 grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-8 gap-4 w-full">
            {specifics.map((specific) => (
              <button
                className="w-100 flex flex-col items-center gap-2"
                key={specific.id}
                onClick={() => handleSelectRecommendation(specific.id)}
              >
                <div
                  className={`flex rounded-full min-w-[54px] w-full pb-[100%] h-0 relative box-border ${
                    selectedRecommendations[specific.id]
                      ? "border-2 border-[#00D4A1]"
                      : ""
                  }`}
                  style={{
                    backgroundImage: specific.imageUrl 
                      ? `url(${specific.imageUrl})`
                      : "url(/api/placeholder/100/100)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {selectedRecommendations[specific.id] && (
                    <div className="flex justify-center items-center rounded-full absolute top-[-5%] right-[5%] w-[25px] h-[25px] bg-[#00D4A1]">
                      <IonIcon icon={checkmark} />
                    </div>
                  )}
                </div>

                <p className="w-full text-center truncate-1-lines">
                  {truncateString(specific.name, 15)}
                </p>
              </button>
            ))}
          </div>
        </div>

        <button
          className="bg-mode-black text-mode-white text-center flex justify-center items-center w-full p-3 text-xl font-bold rounded-lg disabled:opacity-70"
          disabled={Object.keys(selectedRecommendations).length < 1}
          onClick={() => {
            // Your done handler
          }}
        >
          LFG!!
        </button>
      </section>
    </IonPage>
  );
};

export default Recommendation;