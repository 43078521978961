import React from 'react';

interface ColorNumberFormatterProps {
  value: string;
  decimals?: number;
  showPlusSign?: boolean;
  className?: string;
}

const ColorNumberFormatter: React.FC<ColorNumberFormatterProps> = ({
  value,
  className = ''
}) => {
  const getColorClass = (num: number): string => {
    if (num > 0) return 'text-green-500';
    if (num < 0) return 'text-red-500';
    return 'text-white';
  };

  // Handle undefined/null cases
  if (value === null || value === undefined) {
    return <span className="text-white">--</span>;
  }


  return (
    <span className={`font-mono ${getColorClass(parseFloat(value))} ${className}`}>
      {value}%
    </span>
  );
};

export default ColorNumberFormatter;