import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Tabs from '../components/CustomTab';
import React, { useEffect, useMemo } from 'react';
import { useAppContext } from '../context/AppContext';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import TokenCard from '../components/TokenCard';
import TokenImage from '../components/TokenImage';
import NumberFormatter from '../components/NumberFormatter';
import ColorNumberFormatter from '../components/ColorNumberFormatter';
import { Link } from 'react-router-dom';
import { MessageCircle } from 'lucide-react';


interface Feed {
  title: string,
  thumbnail: string,
  descriptionText: string,
  datePublished: string,
  url: string
}

interface TrendTokens {
  tokenName: string,
  address: string,
  marketCap: string,
  price_change_in_m5: string,
  image: string
}

interface QueryFeedDataResponse {
  data: Array<Feed>,
  message: string
}

interface QueryTokenDataResponse {
  data: Array<any>,
  message: string
}

const Home: React.FC = () => {

  const { dispatch, state } = useAppContext();
  const { user } = useDynamicContext();
  const router = useIonRouter();

  const queryClient = useQueryClient();

  useEffect(() => {
    // Only redirect if explicitly not authenticated
    if (state.isAuthenticated === false) {
      console.log('Not authenticated, returning to login'); // Debug log
      router.push('/login', undefined, 'replace');
    }
  }, [state.isAuthenticated]);

  const feed = useQuery<QueryFeedDataResponse>({
    queryKey: ['feed'],
    queryFn: () => fetch('https://narrative-server-production.up.railway.app/post/user/6sgcqYGe877gGQByLuYAiqLs9qCtAK3mW1azpRFx65eP?limit=30').then(res => res.json()),
    staleTime: 2 * 60 * 1000, // Consider data fresh for 5 minutes
    retry: 2, // Retry failed requests twice
    refetchOnWindowFocus: false // Disable automatic refetch on window focus
  });

  const trending = useQuery<TrendTokens[]>({
    queryKey: ['trending'],
    queryFn: () => fetch('https://narrative-server-production.up.railway.app/gecko/trending').then(res => res.json()),
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    retry: 2, // Retry failed requests twice
    refetchOnWindowFocus: false // Disable automatic refetch on window focus
  });

  if (!state.isAuthenticated) {
    return null;
  }

  const FeedItem: React.FC<any> = ({ item }) => {
    const isTweet = item.url.includes('twitter.com');
    
    // Extract username from Twitter description
    const getTwitterUsername = (description: string) => {
      const match = description.match(/— (.+?) \(@.+?\)/);
      return match ? match[1] : '';
    };
  
    // Clean tweet text by removing the attribution
    const cleanTweetText = (text: string) => {
      return text.split('—')[0].trim();
    };
  
    if (isTweet) {
      return (
        <a href={item.url} className="block">
          <div className="bg-[#161616] p-4 border border-[#ffffff1c] hover:border-gray-700 transition-colors">
            <div className="flex items-start gap-3">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 rounded-full border-[#ffffff1c] flex items-center justify-center">
                <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/X_logo.jpg/800px-X_logo.jpg' className="w-6 h-6 text-gray-400" />
                </div>
              </div>
              <div className="flex-1 min-w-0">
                <p className="font-medium text-gray-200 mb-1">
                  {getTwitterUsername(item.descriptionText)}
                </p>
                <p className="text-gray-400 text-sm break-words">
                  {cleanTweetText(item.descriptionText)}
                </p>
                {item.thumbnail && (
                  <div className="mt-3 rounded-lg overflow-hidden">
                    <img 
                      src={item.thumbnail} 
                      alt="Tweet media"
                      className="w-full h-auto"
                    />
                  </div>
                )}
                <p className="text-gray-500 text-sm mt-2">
                  {new Date(item.datePublished).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </a>
      );
    }
  
    return (
      <a href={item.url} className="block">
        <div className="bg-[#161616] p-4 border border-[#ffffff1c] transition-colors">
          {item.thumbnail && (
            <div className="mb-4 overflow-hidden">
              <img 
                src={item.thumbnail} 
                alt={item.title}
                className="w-full h-48 object-cover"
              />
            </div>
          )}
          <h2 className="text-lg font-semibold text-gray-200 mb-2">{item.title}</h2>
          <p className="text-gray-400 text-sm line-clamp-2">{item.descriptionText}</p>
          <p className="text-gray-500 text-sm mt-2">
            {new Date(item.datePublished).toLocaleString()}
          </p>
        </div>
      </a>
    );
  };

  // Memoize tabs to prevent recreating on every render
  const tabs = useMemo(() => [
    {
      id: 'tab1',
      label: 'Curations',
      content: (
        <div className='flex flex-col gap-1'>
          {feed?.data?.data?.map((item, index) => (
             <FeedItem key={index} item={item} />
          ))}
        </div>
      )
    },
    {
      id: 'tab2',
      label: 'Markets',
      content: (
        <div>
          {trending.data?.map((trend) => (
            <div 
            key={trend.address} 
            onClick={() => handleTokenClick(trend.address)}
            onMouseEnter={() => prefetchToken(trend.address)} 
            className='bg-[#161616] text-white px-3 py-3 flex items-center'>
              <div>
                <TokenImage src={trend.image} />
              </div>
              <div className='px-3 h-full flex-col'>
                <span>{trend.tokenName.slice(0, -6)}</span>
                <div>MC: <NumberFormatter value={Number(trend.marketCap)} decimals={3} /></div>
              </div>
              <div className='h-full flex-col ml-auto'>
                <ColorNumberFormatter value={trend.price_change_in_m5} decimals={2} />
              </div>
            </div>
          ))}
        </div>
      )
    },
    {
      id: 'tab3',
      label: 'Sentiment',
      content: <div className='flex justify-center'>Sentiments are coming soon</div>
    }
  ], [feed.data, trending.data]); // Dependencies for useMemo


  const prefetchToken = async (tokenAddress: string) => {
    await queryClient.prefetchQuery({
      queryKey: ["token", tokenAddress],
      queryFn: () => 
        fetch(`https://narrative-server-production.up.railway.app/das/${tokenAddress}`)
          .then(res => res.json()),
      staleTime: 5 * 60 * 1000,
    });
  };


  const handleTokenClick = (tokenAddress: string) => {
    // Ensure data is in cache before navigation
    queryClient.prefetchQuery({
      queryKey: ["token", tokenAddress],
      queryFn: () => 
        fetch(`https://narrative-server-production.up.railway.app/das/${tokenAddress}`)
          .then(res => res.json()),
    }).then(() => {
      router.push(`/token/${tokenAddress}`);
    });
  };

  return (
    <>

      <IonHeader>
        <IonToolbar>
          <IonTitle>Hot</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense" translucent={true}>
          <IonToolbar>
            <text className='text-[0.8rem] px-3'>Because you like...</text>
            <IonTitle size="large">Rap Music</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className='px-3 pb-2 text-[2rem]'>
          <span>🔥</span>
        </div>
        <div className='flex flex-col gap-[0.2rem]'>
         {trending.data?.slice(0,3).map((trend, index: number) => (
          <div 
          key={index}
          className='bg-[#161616] text-white px-3 py-3 flex items-center'
          onClick={() => handleTokenClick(trend.address)}
          onMouseEnter={() => prefetchToken(trend.address)}
          >
          <div>
            <TokenImage src={trend.image} />
          </div>
    
          <div className='px-3 h-full flex-col'>
            <span>{trend.tokenName.slice(0,-6)}</span>
          
            <div>MC: <NumberFormatter value={Number(trend.marketCap)} decimals={3} /></div>
          </div>
    
          <div className='h-full flex-col ml-auto'>
          <ColorNumberFormatter 
          value={trend.price_change_in_m5} 
          decimals={2}
        />
          </div>
        </div>
         ))}

       <Link to="/trends" className="flex py-2 px-3 justify-center">
        <button 
          className="w-full flex items-center justify-between text-white hover:bg-[#242424]"
        >
          See More
        </button>
      </Link>
        </div>

        <Tabs tabs={tabs}/>
    
      </IonContent>

      </>
  );
};

export default Home;
