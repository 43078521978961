import React, { createContext, useContext, useEffect, useState } from "react";
import { useAppContext } from "./AppContext";

type ThemeContextType = {
  darkMode: boolean;
  lightMode: boolean;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) throw new Error("useTheme must be used within a ThemeProvider");
  return context;
};

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark" || false
  );
  const { dispatch } = useAppContext();

  useEffect(() => {
    const root = document.documentElement;
    if (darkMode) {
      root.classList.add("dark");
      dispatch({ type: "THEME", payload: "dark" });
      localStorage.setItem("theme", "dark");
    } else {
      root.classList.remove("dark");
      dispatch({ type: "THEME", payload: "light" });
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  const toggleTheme = () => setDarkMode((prev) => !prev);

  return (
    <ThemeContext.Provider
      value={{ darkMode, toggleTheme, lightMode: !darkMode }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
