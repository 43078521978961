export class WebAuthnService {
    private static readonly AUTH_USERNAME_KEY = 'auth_username';
  
    private static bufferToBase64(buffer: ArrayBuffer): string {
      return btoa(String.fromCharCode(...new Uint8Array(buffer)));
    }
  
    static async register(username: string): Promise<boolean> {
      try {
        const publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions = {
          challenge: Uint8Array.from('randomChallenge', c => c.charCodeAt(0)),
          rp: {
            name: 'Your App Name',
            id: window.location.hostname
          },
          user: {
            id: Uint8Array.from(username, c => c.charCodeAt(0)),
            name: username,
            displayName: username
          },
          pubKeyCredParams: [
            { type: 'public-key', alg: -7 },
            { type: 'public-key', alg: -257 }
          ],
          authenticatorSelection: {
            authenticatorAttachment: 'platform',
            userVerification: 'required'
          },
          timeout: 60000,
          attestation: 'direct'
        };
  
        const credential = await navigator.credentials.create({
          publicKey: publicKeyCredentialCreationOptions
        }) as PublicKeyCredential;
  
        const publicKeyCredential = credential as PublicKeyCredential;
        const response = publicKeyCredential.response as AuthenticatorAttestationResponse;
  
        console.log('Credential ID:', this.bufferToBase64(publicKeyCredential.rawId));
        console.log('Public Key:', this.bufferToBase64(response.getPublicKey() as ArrayBuffer));
        console.log('Authenticator Data:', this.bufferToBase64(response.getAuthenticatorData()));
        console.log('Client Data JSON:', this.bufferToBase64(response.clientDataJSON));
        console.log('Attestation Object:', this.bufferToBase64(response.attestationObject));
  
        localStorage.setItem(this.AUTH_USERNAME_KEY, username);
        return true;
      } catch (error) {
        console.error('Error in registration:', error);
        return false;
      }
    }
  
    static async login(): Promise<boolean> {
      const username = localStorage.getItem(this.AUTH_USERNAME_KEY);
      if (!username) {
        console.error('No username found. Please register first.');
        return false;
      }
  
      try {
        const publicKeyCredentialRequestOptions: PublicKeyCredentialRequestOptions = {
          challenge: Uint8Array.from('randomChallenge', c => c.charCodeAt(0)),
          rpId: window.location.hostname,
          userVerification: 'required',
          timeout: 60000
        };
  
        const assertion = await navigator.credentials.get({
          publicKey: publicKeyCredentialRequestOptions
        }) as PublicKeyCredential;
  
        const response = assertion.response as AuthenticatorAssertionResponse;
  
        console.log('Credential ID:', this.bufferToBase64(assertion.rawId));
        console.log('Authenticator Data:', this.bufferToBase64(response.authenticatorData));
        console.log('Client Data JSON:', this.bufferToBase64(response.clientDataJSON));
        console.log('Signature:', this.bufferToBase64(response.signature));
        console.log('User Handle:', response.userHandle ? this.bufferToBase64(response.userHandle) : 'null');
  
        return true;
      } catch (error) {
        console.error('Error in authentication:', error);
        return false;
      }
    }
  
    static logout(): void {
      localStorage.removeItem(this.AUTH_USERNAME_KEY);
    }
  
    static isAuthenticated(): boolean {
      return !!localStorage.getItem(this.AUTH_USERNAME_KEY);
    }
  }