import React, { useState } from 'react'
import { solanaLogo } from '../types/const';

interface TokenImageProps {
    src: string;
    alt?: string;
    size?: number; // optional size prop, defaults to 50
  }

const TokenImage = ({ src, alt, size = 50 }: TokenImageProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
  
    return (
      <div 
        style={{ width: size, height: size }}
        className="relative rounded-full overflow-hidden bg-gray-800 flex-shrink-0"
      >
        {isLoading && (
          <div className="absolute inset-0">
            <div className="animate-shimmer w-full h-full bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800 bg-[length:200%_100%]" />
          </div>
        )}
        
        {/* change from solana logo to better alt */}
        <img
          src={hasError ? solanaLogo : src}
          alt={alt}
          className={`absolute w-full h-full object-cover transition-opacity duration-200 ${
            isLoading ? 'opacity-0' : 'opacity-100'
          }`}
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setHasError(true);
            setIsLoading(false);
          }}
        />
      </div>
    );
  };

export default TokenImage