import { IonContent, IonHeader, IonPage, IonTitle, } from '@ionic/react';
import Chartjs from '../components/Chart';
import Tabs from '../components/CustomTab';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { Connection } from '@solana/web3.js';
import { useEffect, useState } from 'react';
import TokenCard from '../components/TokenCard';
import { server_url } from '../types/const';

export interface TokenInfo {
  name: string;
  image: string;
  symbol: string;
  balance: number;
  decimals: number;
  pricePerToken: number;
  value: number;
  currency: string;
}

export interface NativeBalance {
  balance: number;
  balanceFormatted: string;
  valueInUsd: number;
  pricePerSol: number;
}

interface TokenPortfolio {
  totalValue: number;
  tokens: TokenInfo[];
}

interface WalletResponse {
  nativeBalance: NativeBalance;
  tokenPortfolio: TokenPortfolio;
  totalValueUsd: number;
}

const API_BASE_URL = "https://narrative-server-production.up.railway.app"

const wallet = "EQvudc9TYK2s8LoNQoxDWQqKtbgPAgJVhmDAyAcKWwAP";



const Wallet: React.FC = () => {
  const [tokens, setTokens] = useState<TokenInfo[]>([]);
  const [nativeState, setNativeState] = useState<NativeBalance>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [balance, setBalance] = useState<WalletResponse["totalValueUsd"] | null>(null);

  const { primaryWallet } = useDynamicContext();


  // console.log(primaryWallet?.address)

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        // const response = await fetch(`${API_BASE_URL}/das/portfolio/${primaryWallet?.address}`);
        const response = await fetch(`${API_BASE_URL}/das/portfolio/${wallet}`);
        if (!response.ok) throw new Error('Failed to fetch data');
        const data: WalletResponse = await response.json();
        
        // Extract all specifics from the categories
        setBalance(data.totalValueUsd)
        setNativeState(data.nativeBalance);
        setTokens(data.tokenPortfolio.tokens)
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch specifics');
      } finally {
        setLoading(false);
      }
    };

    fetchTokens();
  }, [primaryWallet?.address]);



  

  const tabs = [
    {
      id: 'tab1',
      label: 'Coins',
      content: <div className='flex flex-col gap-[0.2rem]'>
        {/* First render native SOL balance */}
        {nativeState && (
          <TokenCard 
            isNative={true} 
            nativeBalance={nativeState} 
            token={null as any}  // TypeScript requirement
          />
        )}
        
        {/* Then map through other tokens */}
        {tokens.map((token) => (
          <TokenCard 
            key={token.symbol} 
            token={token} 
            isNative={false}
          />
        ))}
        </div>,
    },
    {
      id: 'tab2',
      label: 'Indexs',
      content: <div className='px-4 flex justify-center'>You currently dont hold any indexs</div>,
    },
    {
      id: 'tab3',
      label: 'Transactions',
      content: <div className='px-4 flex justify-center'>You have zero transactiosn</div>,
    },
  ];

  
  

  return (
    <>

   {/* <IonHeader>
    <IonToolbar>
     <IonTitle>Wallet</IonTitle>
    </IonToolbar>
    </IonHeader> */}
    <IonContent fullscreen>
            <div className='py-6 px-4'>
            <div className="text-[#7c7c7c]">IFE'S WALLET</div>
            <h3 className=' mt-2 font-bold text-4xl'>${balance || 0}</h3>
            <div className='mt-1'>
            <span className=' text-[#05C396]'>+$102.10(+5.90)</span>
            </div>
            </div>
            {/* <Chartjs data={ch} /> */}

            <Tabs tabs={tabs}/>
    </IonContent>
    </>
  );
};

export default Wallet;
