import { UserProfile } from "@dynamic-labs/sdk-react-core";
import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";

export type ContextStateType = {
  isAuthenticated: boolean;
  user: UserProfile | undefined;
  newUser: boolean;
  theme: "light" | "dark";
};

type AuthAction =
  | { type: "LOGIN", payload: ContextStateType["user"] }
  | { type: "LOGOUT" }
  | { type: "RECOMMEND_DONE" }
  | { type: "THEME"; payload: "light" | "dark" };

type AuthContextType = {
  state: ContextStateType;
  dispatch: Dispatch<AuthAction>;
};

// Initial state
const initialState: ContextStateType = {
  isAuthenticated: false,
  user: undefined,
  newUser: false,
  theme: localStorage.getItem("theme") === "dark" ? "dark" : "light",
};

// Reducer function to handle authentication actions
function authReducer(
  state: ContextStateType,
  action: AuthAction
): ContextStateType {
  switch (action.type) {
    case "LOGIN":
      //   localStorage.setItem("token", "your-auth-token"); // Set your token or any auth info here
      return { 
        ...state, 
        isAuthenticated: true,
        user: action.payload  // This was missing!
      };
    case "LOGOUT":
      //   localStorage.removeItem("token"); // Clear the token from storage
      return { ...state, isAuthenticated: false };
    case "RECOMMEND_DONE":
      return { ...state, newUser: false };
    case "THEME":
      return { ...state, theme: action.payload };
    default:
      return state;
  }
}

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAppContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
