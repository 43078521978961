import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import {
  IonTabs,
  IonTab,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonHeader,
  IonTitle,
  IonContent,
  IonIcon,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import Search from "./pages/Search";
import Profile from "./pages/Profile";
import Cook from "./pages/Cook";
import Wallet from "./pages/Wallet";

import {
  playCircle,
  flaskSharp,
  personSharp,
  search,
  cashSharp,
  flame,
} from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css'; */
import "@ionic/react/css/palettes/dark.system.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";
import Login from "./pages/Login";
import HomeMain from "./pages/MainHome";
import { ContextProvider, useAppContext } from "./context/AppContext";
import Recommendation from "./pages/Recommendation";
import { getRedirectPath } from "./utils/random.";
import { ThemeProvider } from "./context/ThemeContext";
import TokenInfo from "./pages/TokenInfo";

setupIonicReact();

interface RouteConfig {
  path: string;
  component: React.ComponentType<RouteComponentProps<any>>;
  exact?: boolean;
  protected?: boolean;
  requiresNewUser?: boolean;
}

const App: React.FC = () => {
  const { state } = useAppContext();
  const redirectTo = getRedirectPath(state);

  const routes: RouteConfig[] = [
    {
      path: "/login",
      component: Login,
      exact: true,
    },
    {
      path: "/recommendation",
      component: Recommendation,
      exact: true,
      protected: true,
      requiresNewUser: true,
    },
    {
      path: "/home",
      component: HomeMain,
      exact: true,
      protected: true,
    },
    {
      path: "/search",
      component: Search,
      exact: true,
      protected: true,
    },
    {
      path: "/profile",
      component: Profile,
      exact: true,
      protected: true,
    },
    {
      path: "/cook",
      component: Cook,
      exact: true,
      protected: true,
    },
    {
      path: "/wallet",
      component: Wallet,
      exact: true,
      protected: true,
    },
    {
      path: "/token/:tokenaddress",
      component: TokenInfo,
      exact: true,
      protected: false,
    },
  ];


  const ProtectedRoute: React.FC<{
    component: React.ComponentType<RouteComponentProps<any>>;
    requiresNewUser?: boolean;
    path: string;
    exact?: boolean;
  }> = ({ component: Component, requiresNewUser, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!state?.isAuthenticated) {
          return <Redirect to="/login" />;
        }

        if (requiresNewUser && !state?.newUser) {
          return <Redirect to={redirectTo} />;
        }

        if (!requiresNewUser && state?.newUser) {
          return <Redirect to="/recommendation" />;
        }

        return <Component {...props} />;
      }}
    />
  );

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          {routes.map((route) => {
            if (route.protected) {
              return (
                <ProtectedRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  requiresNewUser={route.requiresNewUser}
                />
              );
            }

            return (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                render={(props) => {
                  if (state?.isAuthenticated && route.path === '/login') {
                    return <Redirect to={redirectTo} />;
                  }
                  return <route.component {...props} />;
                }}
              />
            );
          })}

          <Route exact path="/">
            <Redirect to={redirectTo} />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

const WrappedApp: React.FC = () => (
  <ContextProvider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </ContextProvider>
);

export default WrappedApp;
