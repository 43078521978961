import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import React from 'react';

interface TokenInfo {
  address: string;
  name: string;
  symbol: string;
  coingecko_coin_id: string;
}

interface OHLCVData {
  data: {
    id: string;
    type: string;
    attributes: {
      ohlcv_list: [number, number, number, number, number, number][];
    };
  };
  meta: {
    base: TokenInfo;
    quote: TokenInfo;
  };
}

interface ChartProps {
  data: OHLCVData;
  timeframe: 'hour' | 'day' | 'minute';
  onTimeframeChange: (timeframe: 'hour' | 'day' | 'minute') => void;
}

// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Chartjs: React.FC<ChartProps> = ({ data, timeframe, onTimeframeChange }) => {

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    switch (timeframe) {
      case 'minute':
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
      case 'hour':
        return date.toLocaleTimeString('en-US', { hour: '2-digit' });
      case 'day':
        return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
      default:
        return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    }
  };

  // Process the OHLCV data
  const processData = () => {
    if (!data?.data?.attributes?.ohlcv_list) {
      return {
        timestamps: [],
        closingPrices: []
      };
    }
    
    const ohlcvList = data.data.attributes.ohlcv_list;
    
    // Reverse the array to show oldest to newest
    const reversedData = [...ohlcvList].reverse();
    
    // Extract timestamps and closing prices
    const timestamps = reversedData.map(item => formatTimestamp(item[0]));
    
    const closingPrices = reversedData.map(item => item[3]); // Index 3 is closing price
    
    return {
      timestamps,
      closingPrices
    };
  };

  const { timestamps, closingPrices } = processData();

  //arrray formmatting
  const selectSixItems = (arr: string | any[]) => {
    if (arr.length <= 6) return arr; // Return full array if it's 6 or fewer items
    
    // Get first and last
    const first = arr[0];
    const last = arr[arr.length - 1];
    
    // Calculate step size for middle items
    const remainingLength = arr.length - 2; // Subtract first and last
    const step = Math.ceil(remainingLength / 5); // We need 4 items, so divide by 5
    
    // Get middle items
    const middleItems = [];
    for (let i = step; middleItems.length < 4 && i < arr.length - 1; i += step) {
      middleItems.push(arr[i]);
    }
    
    // Combine all items
    return [first, ...middleItems, last];
  };

  const chartData = {
    labels: timestamps,
    datasets: [
      {
        label: `${data?.meta?.base?.symbol || 'Price'}`,
        data: closingPrices,
        borderColor: 'rgba(5, 195, 150, 1)',
        backgroundColor: (context: { chart: { chartArea: any; ctx?: any; }; }) => {
          const bgColors = [
            'rgba(6, 255, 195, 0.2)',
            'rgba(0, 0, 0, 0)'
          ];
          
          if (!context.chart.chartArea) {
            return;
          }
          
          const { ctx, chartArea: { top, bottom } } = context.chart;
          const gradientFill = ctx.createLinearGradient(0, top, 0, bottom);
          gradientFill.addColorStop(0, bgColors[0]);
          gradientFill.addColorStop(0.9, bgColors[1]);
          return gradientFill;
        },
        fill: true,
        tension: 0,
        pointRadius: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
        y: {
          display: false,  // Hide the Y-axis
          grid: {
            display: false,  // Remove Y-axis grid lines
          },
          beginAtZero: true
        },
        x: {
          ticks: {
            color: 'rgba(148, 148, 148, 0.68)',
          },
          grid: {
            display: false,  // Remove X-axis grid lines
          },
          border: {
            display: false
          }
        },
      },
      elements: {
        line: {
          borderWidth: 2,
        },
      },
      plugins: {
        legend: {
          display: false,  // Optionally hide the legend
        },

        
      },
  };

  return (
    <div style={{ 
      marginTop: 10, 
      marginBottom: 30, 
      paddingTop: 10, 
      paddingBottom: 20, 
      backgroundColor: '', 
      paddingLeft: 5, 
      paddingRight: 10,
      height: '300px'
    }}>
      <Line data={chartData} options={chartOptions} />
      <div className="w-full mt-5 justify-between px-5 flex flex-row">
      <button 
          className={`bg-[#101010] ${timeframe === 'minute' ? 'border-[#a5a5a5] border-[2px]' : ''} text-white min-w-[3rem] px-3 py-2 rounded-lg`}
          onClick={() => onTimeframeChange('minute')}
        >
          1M
        </button>
      <button 
          className={`bg-[#101010] ${timeframe === 'hour' ? 'border-[#a5a5a5] border-[2px]' : ''} text-white min-w-[3rem] px-3 py-2 rounded-lg`}
          onClick={() => onTimeframeChange('hour')}
        >
          1H
        </button>
        <button 
          className={`bg-[#101010] ${timeframe === 'day' ? 'border-[#a5a5a5] border-[2px]' : ''} text-white min-w-[3rem] px-3 py-2 rounded-lg`}
          onClick={() => onTimeframeChange('day')}
        >
          1D
        </button>
      </div>
    </div>
  );
};

export default Chartjs;