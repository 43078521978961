import { Transaction, VersionedTransaction } from "@solana/web3.js";

import { useForm } from "react-hook-form";
import axios from "axios";
import bs58 from "bs58";
import { useState, useEffect } from "react";

import styles from "../theme/turnkey.css";
import { TWalletDetails } from "../types";

import { useTurnkey } from "@turnkey/sdk-react";
import { TurnkeySigner } from "@turnkey/solana";
import { broadcast, connect } from "../utils/Turnkey";

type subOrgFormData = {
  subOrgName: string;
};

type signMessageFormData = {
  messageToSign: string;
};

type signTransactionFormData = {
  destinationAddress: string;
  amount: string;
};

type TWalletState = TWalletDetails | null;

type TSignedMessage = {
  message: string;
  base58Signature: string;
  base64Signature: string;
  rawSignature: string;
} | null;

const base64Encode = (payload: ArrayBuffer): string =>
  Buffer.from(payload).toString("base64");

const humanReadableDateTime = (): string => {
  return new Date().toLocaleString().replaceAll("/", "-").replaceAll(":", ".");
};

export default function TurnkeyService() {
  const { turnkey, passkeyClient } = useTurnkey();

  // Wallet is used as a proxy for logged-in state
  const [wallet, setWallet] = useState<TWalletState>(null);
  const [signedMessage, setSignedMessage] = useState<TSignedMessage>(null);
  const [signedTransaction, setSignedTransaction] = useState<string | null>(
    null
  );

  const { handleSubmit: subOrgFormSubmit } = useForm<subOrgFormData>();
  const {
    register: signMessageFormRegister,
    handleSubmit: signMessageFormSubmit,
  } = useForm<signMessageFormData>();
  const {
    register: signTransactionFormRegister,
    handleSubmit: signTransactionFormSubmit,
  } = useForm<signTransactionFormData>({
    defaultValues: {
      destinationAddress: "tkhqC9QX2gkqJtUFk2QKhBmQfFyyqZXSpr73VFRi35C",
    },
  });
  const { register: _loginFormRegister, handleSubmit: loginFormSubmit } =
    useForm();

  // First, logout user if there is no current wallet set
  useEffect(() => {
    (async () => {
      if (!wallet) {
        await turnkey?.logoutUser();
      }
    })();
  });

  const signMessage = async (data: signMessageFormData) => {
    if (!wallet) {
      throw new Error("wallet not found");
    }

    const turnkeySigner = new TurnkeySigner({
      organizationId: wallet.subOrgId,
      client: passkeyClient!,
    });

    const messageAsUint8Array = Buffer.from(data.messageToSign);

    const signedMessage = await turnkeySigner.signMessage(
      messageAsUint8Array,
      wallet.address
    );

    const base58EncodedSignature = bs58.encode(signedMessage);
    const base64EncodedSignature = base64Encode(signedMessage);
    const rawSignature = Buffer.from(signedMessage).toString("hex");

    setSignedMessage({
      message: data.messageToSign,
      base58Signature: base58EncodedSignature,
      base64Signature: base64EncodedSignature,
      rawSignature,
    });
  };

  const signTransaction = async (data: signTransactionFormData) => {
    if (!wallet) {
      throw new Error("wallet not found");
    }

    const turnkeySigner = new TurnkeySigner({
      organizationId: wallet.subOrgId,
      client: passkeyClient!,
    });

    // request backend to (create and) sign a transaction

    /// add nest js endpoint path
    const res = await axios.post("/api/createTransaction", {
      fromAddress: wallet.address,
      destinationAddress: data.destinationAddress,
      amount: data.amount,
    });

    const { serializedTransaction } = res.data;

    const deserializedTransaction = VersionedTransaction.deserialize(
      Buffer.from(serializedTransaction, "base64")
    );

    // add user signature
    await turnkeySigner.addSignature(deserializedTransaction, wallet.address);

    const connection = connect();

    // broadcast
    const transactionHash = await broadcast(
      connection,
      deserializedTransaction
    );

    setSignedTransaction(
      `https://explorer.solana.com/tx/${transactionHash}?cluster=devnet`
    );
  };

  const createSubOrgAndWallet = async () => {
    const subOrgName = `Turnkey Solana + Passkey Demo - ${humanReadableDateTime()}`;

    console.log(subOrgName)

    const credential = await passkeyClient?.createUserPasskey({
      publicKey: {
        rp: {
          id: "5173-omnilabz-pwa-bqx5sfnzgof.ws-eu116.gitpod.io",
          name: "Turnkey Solana Passkey Demo",
        },
        user: {
          name: subOrgName,
          displayName: subOrgName,
        },
      },
    });

    console.log(await passkeyClient)

    if (!credential?.encodedChallenge || !credential?.attestation) {
      return false;
    }

    ///here again
    const res = await axios.post("/api/createSubOrg", {
      subOrgName: subOrgName,
      challenge: credential?.encodedChallenge,
      attestation: credential?.attestation,
    });

    const response = res.data as TWalletDetails;
    setWallet(response);
  };

  const login = async () => {
    try {
      // Initiate login (read-only passkey session)
      const loginResponse = await passkeyClient?.login();
      if (!loginResponse?.organizationId) {
        return;
      }

      const currentUserSession = await turnkey?.currentUserSession();
      if (!currentUserSession) {
        return;
      }

      const walletsResponse = await currentUserSession?.getWallets();
      if (!walletsResponse?.wallets[0].walletId) {
        return;
      }

      const walletId = walletsResponse?.wallets[0].walletId;
      const walletAccountsResponse =
        await currentUserSession?.getWalletAccounts({
          organizationId: loginResponse?.organizationId,
          walletId,
        });
      if (!walletAccountsResponse?.accounts[0].address) {
        return;
      }

      setWallet({
        id: walletId,
        address: walletAccountsResponse?.accounts[0].address,
        subOrgId: loginResponse.organizationId,
      } as TWalletDetails);
    } catch (e: any) {
      const message = `caught error: ${e.toString()}`;
      console.error(message);
      alert(message);
    }
  };

  return (
    <main>
      <a href="https://turnkey.com" target="_blank" rel="noopener noreferrer">
        <img
          src="/logo.svg"
          alt="Turnkey Logo"
          width={100}
          height={24}
        />
      </a>
      <div>
        {wallet !== null && (
          <div>
            Your sub-org ID: <br />
            <span>{wallet.subOrgId}</span>
          </div>
        )}
        {wallet && (
          <div>
            SOL address: <br />
            <span>{wallet.address}</span>
          </div>
        )}
        {signedMessage && (
          <div>
            Message: <br />
            <span>{signedMessage.message}</span>
            <br />
            <br />
            Signature: <br />
            <span>{signedMessage.base58Signature}</span>
          </div>
        )}
        {wallet && signedMessage && (
          <div>
            Raw public key: <br />
            <span>
              {base64Encode(bs58.decode(wallet.address))}
            </span>
            <br />
            <br />
            Signature (for verifying): <br />
            <span >{signedMessage.base64Signature}</span>
            <br />
            <br />
            <a
              href="https://tweetnacl.js.org/#/sign"
              target="_blank"
              rel="noopener noreferrer"
            >
              Verify with tweetnacl
            </a>
          </div>
        )}
      </div>
      {!wallet && (
  <div>
    <h2>Create a new wallet</h2>
    <p>
      We'll prompt your browser to create a new passkey. The details
      (credential ID, authenticator data, client data, attestation) will
      be used to create a new{" "}
      <a
        href="https://docs.turnkey.com/getting-started/sub-organizations"
        target="_blank"
        rel="noopener noreferrer"
      >
        Turnkey Sub-Organization
      </a>{" "}
      and a new{" "}
      <a
        href="https://docs.turnkey.com/getting-started/wallets"
        target="_blank"
        rel="noopener noreferrer"
      >
        Wallet
      </a>{" "}
      within it.
    </p>
    <form onSubmit={(e) => {
      e.preventDefault();
      console.log("Form submitted");
      createSubOrgAndWallet();
    }}>
      <button type="submit">Create new wallet</button>
    </form>
  </div>
)}
      {wallet && (
        <div>
          <h2>Now let&apos;s sign a message!</h2>
          <p>
            We&apos;ll use a{" "}
            <a
              href="https://solana.com/docs/clients/javascript"
              target="_blank"
              rel="noopener noreferrer"
            >
              Solana web3js account
            </a>{" "}
            to do this, using{" "}
            <a
              href="https://www.npmjs.com/package/@turnkey/solana"
              target="_blank"
              rel="noopener noreferrer"
            >
              @turnkey/solana
            </a>
            .
          </p>
          <form
            onSubmit={signMessageFormSubmit(signMessage)}
          >
            <input
              {...signMessageFormRegister("messageToSign")}
              placeholder="Write something to sign..."
            />
            <input
              type="submit"
              value="Sign Message"
            />
          </form>
        </div>
      )}
      {wallet && (
        <div>
          <h2>... and now let&apos;s sign a transaction!</h2>
          <form
            onSubmit={signTransactionFormSubmit(signTransaction)}
          >
            <input
              {...signTransactionFormRegister("amount")}
              placeholder="Amount (Lamports)"
            />
            <input
              {...signTransactionFormRegister("destinationAddress")}
            />
            <input
              type="submit"
              value="Sign and Broadcast Transaction"
            />
          </form>
          {signedTransaction && (
            <div>
              <p>
                🚀 Transaction broadcasted and confirmed!
                <br />
                <br />
                <a
                  href={signedTransaction}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {signedTransaction}
                </a>
              </p>
            </div>
          )}
        </div>
      )}
    </main>
  );
}